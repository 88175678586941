import Model from 'src/utilities/Model';
import Updater from 'src/utilities/Updater';

declare module 'src/utilities/Services' {
  export interface ServicesList {
    identity: IdentityService;
  }
}

export type Me = Model.User;

type Identity = {
  token: string;
  user: Model.User;
};

export default class IdentityService {
  private identity: Identity | null = null;
  readonly updater = new Updater();

  async init() {
    const stored = localStorage.getItem('whoami');
    if (stored) {
      const identity = JSON.parse(stored) as Identity;
      this.identity = identity;
      this.updater.update();
    }
  }

  setIdentity(identity: Identity) {
    this.identity = identity;
    this.updater.update();
    localStorage.setItem('whoami', JSON.stringify(identity));
  }

  logOut() {
    this.identity = null;
    this.updater.update();
    localStorage.removeItem('whoami');
  }

  useIsLoggingOut() {
    return this.updater.useValue(() => !!this.identity);
  }

  useIdentity() {
    return this.updater.useValue(() => this.identity);
  }

  useMe() {
    return this.updater.useValue(() => {
      if (!this.identity) throw new Error('No connected user');
      return this.identity.user;
    });
  }

  getToken() {
    if (this.identity) return this.identity.token;
    else return null;
  }
}
