import {sortBy, uniqBy} from 'lodash';
import Model from './Model';

export default function buildRoll<TType extends Model.List.Photo>(
  photos: Array<TType>,
  length?: number,
): Array<TType> {
  let roll = [...photos];
  roll = uniqBy(roll, p => p.id);
  roll = sortBy(roll, p => p.index * -1);
  if (typeof length === 'number') roll = roll.slice(0, length);
  return roll;
}
