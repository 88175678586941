/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { isAndroid, isIOS } from 'mobile-device-detect';
import { Fragment } from "react";
import { generatePath, Link, Navigate, useMatch } from "react-router-dom";
import AsyncValueView from "src/components/AsyncValueView";
import Typo from "src/components/Typo";
import environement from "src/environment";
import AsyncValue from "src/utilities/AsyncValue";
import Paths from "src/utilities/Paths";
import Services from "src/utilities/Services";

export default function EventScreen() {

    const containerCss = css`
        min-height : 100vh ;
        padding-top : 20vh ;
        background : linear-gradient(to top right, #EE0979, #FF6A00);
        display : flex ;
        flex-direction : column ;
        align-items : center ;
        color : white ;
    `

    const imageCss = css`
       width : 50% ;
    `

    const linksCss = css`
    text-align : center ;
    padding-top : 5vh ;
    padding-left : 20px ;
    padding-right : 20px ;
    a{
        color : inherit ;
        text-decoration : none ;
        &:hover{
            text-decoration : underline ;
        }
    }
    `

    const params = useMatch(Paths.Events);
    if (!params) throw new Error("404");
    const eventId = params.params.event
    if (!eventId) throw new Error("404");

    const { watch, deploy } = Services.use()


    const eventResult = watch.useEventQuery({ variables: { event: window.location.href } })
    const eventValue = AsyncValue.useQueryResult(eventResult, (p) => p.event)

    const badgeCss = css`
        height :40px ;
    `

    return (<div css={containerCss}>
        <img src={"/hoovee.svg"} css={imageCss} alt="Hoovee" />
        <div css={linksCss}>
            <AsyncValueView value={eventValue} renderValue={(event) => {

                const androidNode = <Navigate to={generatePath(Paths.Shoot, { event: event.id })} />

                const iosNode = <Fragment>
                    <Typo center>Pour partager vos photos, téléchargez l'application sur l'AppStore</Typo>
                    <br />
                    <a href={environement.findString("REACT_APP_APP_STORE_URL")} target="blank">
                        <img src={"/AppStore.png"} css={badgeCss} alt="Télécharger sur l'App Store" />
                    </a>
                </Fragment>

                const slideshowNode = <Fragment>
                    <div>
                        <Link to={generatePath(Paths.Slideshow, { event: event.id })}>
                            <Typo center>Diffuser les photos sur cet écran</Typo>
                        </Link>
                    </div>
                </Fragment>

                return <div css={linksCss}>
                    <Typo name="title" center>{event.name}</Typo>
                    <br />
                    {isAndroid ? androidNode : isIOS ? iosNode : slideshowNode}
                </div>
            }} />
        </div>

    </div>)
}