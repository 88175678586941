import {EventEmitter} from 'events';
import {DependencyList, useEffect, useState} from 'react';

const emitter = new EventEmitter();

export default class Updater {
  private eventName: string;

  constructor() {
    this.eventName = `event${Date.now()}`;
  }

  update() {
    emitter.emit(this.eventName);
  }

  onUpdate(cb: () => void): () => void {
    emitter.on(this.eventName, cb);
    return () => emitter.off(this.eventName, cb);
  }

  useValue<TValue>(getter: () => TValue, deps: DependencyList = []): TValue {
    const [value, setValue] = useState(getter);
    useEffect(() => {
      const cb = () => setValue(getter);
      cb();
      const unsub = this.onUpdate(cb);
      return () => unsub();
    }, deps);
    return value;
  }

  useReaction(reaction: () => void) {
    useEffect(() => {
      const unsub = this.onUpdate(reaction);
      return () => unsub();
    }, []);
  }
}
