/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Typo from './Typo';

type LoadingViewProps = {
    message?: string;
};

export default function LoadingView(props: LoadingViewProps) {

    const { message = "Chargement" } = props
    const containerCss = css``

    return (
        <div css={containerCss}>
            <Typo center>{message}</Typo>
        </div>
    );
}

