/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { PropsWithChildren } from "react"
import Typos, { TypoName } from "src/utilities/Typos"


type TypoProps = PropsWithChildren<{
    name?: TypoName
    center?: boolean
}>

export default function Typo(props: TypoProps) {

    const { name = "body", center } = props;

    const containerCss = css`
        ${Typos[name]} ;
        text-align : ${center ? "center" : "left"} ;
    `

    return (<div css={containerCss}>{props.children}</div>)
}