import {
  ApolloClient,
  ApolloLink,
  BaseMutationOptions,
  concat,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import {GraphQLWsLink} from '@apollo/client/link/subscriptions';
import {getMainDefinition} from '@apollo/client/utilities';
import {createClient} from 'graphql-ws';
import IdentityService from '../IdentityService';
import {
  useAlbumQuery,
  useCreateEventMutation,
  useCreatePhotoMutation,
  useDeleteAccountMutation,
  useDisablePhotoMutation,
  useEventsQuery,
  useJoinEventMutation,
  useLoginMutation,
  useMyRollQuery,
  useOnPhotoCreatedSubscription,
  useOnPhotoDisabledSubscription,
  useParticipationQuery,
} from './types.generated';

declare module 'src/utilities/Services' {
  export interface ServicesList {
    enjoy: EnjoyEndpoint;
  }
}

type EnjoyEndpointConfig = {
  http_url: string;
  ws_url: string;
};

type EnjoyEndpointDeps = {
  identity: IdentityService;
};

export default class EnjoyEndpoint {
  private config: EnjoyEndpointConfig;
  private deps: EnjoyEndpointDeps;
  readonly client: ApolloClient<NormalizedCacheObject>;

  constructor(config: EnjoyEndpointConfig, deps: EnjoyEndpointDeps) {
    this.config = config;
    this.deps = deps;

    const authMiddleware = new ApolloLink((operation, forward) => {
      let context = operation.getContext();
      const token = this.deps.identity.getToken();
      if (token) {
        context = {
          ...context,
          headers: {...context.headers, authorization: `Bearer ${token}`},
        };
      }
      operation.setContext(context);
      return forward(operation);
    });

    const httpLink = createHttpLink({
      uri: this.config.http_url,
    });

    const cache = new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            myRoll: {merge: (e, i) => [...i]},
            album: {merge: (e, i) => [...i]},
          },
        },
      },
    });

    const errorLink = onError(({graphQLErrors, networkError}) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(graphQLError => {
          if (graphQLError.extensions) {
            const code = graphQLError.extensions.code;
            if (code === 'UNAUTHENTICATED') {
              this.deps.identity.logOut();
            }
          }
        });
      }
    });

    const wsLink = new GraphQLWsLink(
      createClient({
        url: config.ws_url,
        connectionParams: () => {
          const token = this.deps.identity.getToken();
          return {authorization: token};
        },
      }),
    );

    const fullHttpLink = concat(authMiddleware, concat(errorLink, httpLink));

    const splitLink = split(
      ({query}) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      fullHttpLink,
    );

    this.client = new ApolloClient({
      cache,
      link: splitLink,
    });
  }

  init() {
    // this.deps.identity.identity.onChange(identity => {
    //   if (!identity) {
    //     this.client.resetStore();
    //   }
    // });
    return this;
  }

  useEventsQuery = useEventsQuery;
  useLoginMutation = useLoginMutation;
  useCreateEventMutation = useCreateEventMutation;
  useParticipationQuery = useParticipationQuery;
  useJoinEventMutation = useJoinEventMutation;
  useCreatePhotoMutation = useCreatePhotoMutation;
  useMyRollQuery = useMyRollQuery;
  useAlbumQuery = useAlbumQuery;
  useDisablePhotoMutation = useDisablePhotoMutation;
  useDeleteAccountMutation = useDeleteAccountMutation;
  useOnPhotoCreatedSubscription = useOnPhotoCreatedSubscription;
  useOnPhotoDisabledSubscription = useOnPhotoDisabledSubscription;
}

export type MutationOptionsData<TOptions extends BaseMutationOptions> =
  TOptions extends BaseMutationOptions<infer TData, any, any, any>
    ? TData
    : never;

export type MutationOptionsVariables<TOptions extends BaseMutationOptions> =
  TOptions extends BaseMutationOptions<any, infer TVariables, any, any>
    ? TVariables
    : never;
