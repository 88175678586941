import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from './hooks';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: any;
  /** Null value */
  Null: any;
};

export type Event = {
  __typename?: 'Event';
  counter: Scalars['Int'];
  id: Scalars['String'];
  invitation_token: Scalars['String'];
  name: Scalars['String'];
  roll: Array<Photo>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _?: Maybe<Scalars['String']>;
};

export type OnPhotoCreatedPayload = {
  __typename?: 'OnPhotoCreatedPayload';
  counter: Scalars['Int'];
  photo: Photo;
};

export type OnPhotoDisabledPayload = {
  __typename?: 'OnPhotoDisabledPayload';
  counter: Scalars['Int'];
  photo: Scalars['String'];
};

export type Participation = {
  __typename?: 'Participation';
  event: Event;
  id: Scalars['String'];
  last_photo?: Maybe<Photo>;
  ref_event: Scalars['String'];
  ref_user: Scalars['String'];
  user: User;
};

export type Photo = {
  __typename?: 'Photo';
  blurhash: Scalars['String'];
  creation: Scalars['Date'];
  id: Scalars['String'];
  index: Scalars['Int'];
  local_key: Scalars['String'];
  original: Scalars['String'];
  ref_participation: Scalars['String'];
  thumbnail: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  _?: Maybe<Scalars['String']>;
  album: Array<Photo>;
  event: Event;
};


export type QueryAlbumArgs = {
  event: Scalars['String'];
};


export type QueryEventArgs = {
  event: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  _?: Maybe<Scalars['String']>;
  onPhotoCreated: OnPhotoCreatedPayload;
  onPhotoDisabled: OnPhotoDisabledPayload;
};


export type SubscriptionOnPhotoCreatedArgs = {
  event: Scalars['String'];
};


export type SubscriptionOnPhotoDisabledArgs = {
  event: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type AlbumQueryVariables = Exact<{
  event: Scalars['String'];
}>;


export type AlbumQuery = { __typename?: 'Query', album: Array<{ __typename?: 'Photo', id: string, thumbnail: string, original: string, blurhash: string, index: number, local_key: string, creation: any }> };

export type EventQueryVariables = Exact<{
  event: Scalars['String'];
}>;


export type EventQuery = { __typename?: 'Query', event: { __typename?: 'Event', id: string, name: string, counter: number } };

export type OnPhotoCreatedSubscriptionVariables = Exact<{
  event: Scalars['String'];
}>;


export type OnPhotoCreatedSubscription = { __typename?: 'Subscription', onPhotoCreated: { __typename?: 'OnPhotoCreatedPayload', counter: number, photo: { __typename?: 'Photo', id: string, thumbnail: string, original: string, blurhash: string, index: number, local_key: string, creation: any } } };

export type OnPhotoDisabledSubscriptionVariables = Exact<{
  event: Scalars['String'];
}>;


export type OnPhotoDisabledSubscription = { __typename?: 'Subscription', onPhotoDisabled: { __typename?: 'OnPhotoDisabledPayload', photo: string, counter: number } };

export type EventCounterFragment = { __typename?: 'Event', counter: number };

export type PhotoFullFragment = { __typename?: 'Photo', id: string, thumbnail: string, original: string, blurhash: string, index: number, local_key: string, creation: any };

export type PhotoReferenceFragment = { __typename?: 'Photo', id: string, thumbnail: string, blurhash: string, index: number };

export const EventCounterFragmentDoc = gql`
    fragment EventCounter on Event {
  counter
}
    `;
export const PhotoFullFragmentDoc = gql`
    fragment PhotoFull on Photo {
  id
  thumbnail
  original
  blurhash
  index
  local_key
  creation
}
    `;
export const PhotoReferenceFragmentDoc = gql`
    fragment PhotoReference on Photo {
  id
  thumbnail
  blurhash
  index
}
    `;
export const AlbumDocument = gql`
    query Album($event: String!) {
  album(event: $event) {
    ...PhotoFull
  }
}
    ${PhotoFullFragmentDoc}`;

/**
 * __useAlbumQuery__
 *
 * To run a query within a React component, call `useAlbumQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlbumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlbumQuery({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useAlbumQuery(baseOptions: ApolloReactHooks.QueryHookOptions<AlbumQuery, AlbumQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<AlbumQuery, AlbumQueryVariables>(AlbumDocument, options);
      }
export function useAlbumLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AlbumQuery, AlbumQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<AlbumQuery, AlbumQueryVariables>(AlbumDocument, options);
        }
export type AlbumQueryHookResult = ReturnType<typeof useAlbumQuery>;
export type AlbumLazyQueryHookResult = ReturnType<typeof useAlbumLazyQuery>;
export type AlbumQueryResult = Apollo.QueryResult<AlbumQuery, AlbumQueryVariables>;
export const EventDocument = gql`
    query Event($event: String!) {
  event(event: $event) {
    id
    name
    counter
  }
}
    `;

/**
 * __useEventQuery__
 *
 * To run a query within a React component, call `useEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventQuery({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useEventQuery(baseOptions: ApolloReactHooks.QueryHookOptions<EventQuery, EventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<EventQuery, EventQueryVariables>(EventDocument, options);
      }
export function useEventLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventQuery, EventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<EventQuery, EventQueryVariables>(EventDocument, options);
        }
export type EventQueryHookResult = ReturnType<typeof useEventQuery>;
export type EventLazyQueryHookResult = ReturnType<typeof useEventLazyQuery>;
export type EventQueryResult = Apollo.QueryResult<EventQuery, EventQueryVariables>;
export const OnPhotoCreatedDocument = gql`
    subscription OnPhotoCreated($event: String!) {
  onPhotoCreated(event: $event) {
    photo {
      ...PhotoFull
    }
    counter
  }
}
    ${PhotoFullFragmentDoc}`;

/**
 * __useOnPhotoCreatedSubscription__
 *
 * To run a query within a React component, call `useOnPhotoCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnPhotoCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnPhotoCreatedSubscription({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useOnPhotoCreatedSubscription(baseOptions: ApolloReactHooks.SubscriptionHookOptions<OnPhotoCreatedSubscription, OnPhotoCreatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<OnPhotoCreatedSubscription, OnPhotoCreatedSubscriptionVariables>(OnPhotoCreatedDocument, options);
      }
export type OnPhotoCreatedSubscriptionHookResult = ReturnType<typeof useOnPhotoCreatedSubscription>;
export type OnPhotoCreatedSubscriptionResult = Apollo.SubscriptionResult<OnPhotoCreatedSubscription>;
export const OnPhotoDisabledDocument = gql`
    subscription OnPhotoDisabled($event: String!) {
  onPhotoDisabled(event: $event) {
    photo
    counter
  }
}
    `;

/**
 * __useOnPhotoDisabledSubscription__
 *
 * To run a query within a React component, call `useOnPhotoDisabledSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnPhotoDisabledSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnPhotoDisabledSubscription({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useOnPhotoDisabledSubscription(baseOptions: ApolloReactHooks.SubscriptionHookOptions<OnPhotoDisabledSubscription, OnPhotoDisabledSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<OnPhotoDisabledSubscription, OnPhotoDisabledSubscriptionVariables>(OnPhotoDisabledDocument, options);
      }
export type OnPhotoDisabledSubscriptionHookResult = ReturnType<typeof useOnPhotoDisabledSubscription>;
export type OnPhotoDisabledSubscriptionResult = Apollo.SubscriptionResult<OnPhotoDisabledSubscription>;