import { css, Global } from "@emotion/react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { Fragment, useEffect, useState } from "react";
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'reset-css';
import environment from 'src/environment';
import Services, { ServicesList } from "src/utilities/Services";
import AppRouter from "./screens/AppRouter";
import DeployService from "./services/DeployService";
import EnjoyEndpoint from "./services/EnjoyEndpoint";
import IdentityService from "./services/IdentityService";
import WatchEndpoint from "./services/WatchEndpoint";


Sentry.init({
  dsn: environment.findString("REACT_APP_HOOVEE_SENTRY_DSN"),
  environment: environment.findString("REACT_APP_HOOVEE_SENTRY_ENVIRONMENT"),
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(<App />);

function App() {

  const globalCss = css`
    body{
      font-family: 'Outfit', sans-serif;
    }
    *{
      box-sizing : border-box ;
    } 
  `

  const [services, setServices] = useState<ServicesList | null>(null);

  useEffect(() => {
    loadServices().then(setServices);
  }, []);

  if (!services) return (<Fragment />)

  return <Services.Provider services={services}>
    <Global styles={globalCss} />
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
  </Services.Provider>;
}


async function loadServices(): Promise<ServicesList> {


  const watch = new WatchEndpoint(
    {
      http_url: environment.findString("REACT_APP_HOOVEE_WATCH_HTTP_ENDPOINT_URL"),
      ws_url: environment.findString("REACT_APP_HOOVEE_WATCH_WS_ENDPOINT_URL"),
    },
    {},
  ).init();

  const identity = new IdentityService()
  await identity.init();

  const enjoy = new EnjoyEndpoint(
    {
      http_url: environment.findString("REACT_APP_HOOVEE_ENJOY_HTTP_ENDPOINT_URL"),
      ws_url: environment.findString("REACT_APP_HOOVEE_WATCH_WS_ENDPOINT_URL"),
    },
    { identity },
  )

  const deploy = new DeployService(
    {},
    { watch },
  )

  return { identity, enjoy, watch, deploy };
}
