/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export default function HomeScreen() {

    const containerCss = css`
        min-height : 100vh ;
        padding-top : 20vh ;
        background : linear-gradient(to top right, #EE0979, #FF6A00);
        display : flex ;
        flex-direction : column ;
        align-items : center ;
        color : white ;
    `

    const imageCss = css`
       width : 50% ;
    `

    const linksCss = css`
    text-align : center ;
    padding-top : 20vh ;
    a{
        color : inherit ;
        text-decoration : none ;
        &:hover{
            text-decoration : underline ;
        }
    }
    `


    return (<div css={containerCss}>
        <img src={"/hoovee.svg"} css={imageCss} alt="Hoovee" />
        <div css={linksCss}>
            <a href="./CGU.pdf" download={true} target="_blank">Conditions générales d'utilisation</a><br /><br />
            <a href="./Confidentialité.pdf" download={true} target="_blank">Politique de confidentialité</a>
        </div>
    </div>)
}