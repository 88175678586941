import { Route, Routes, useMatch } from "react-router-dom";
import Paths from "../utilities/Paths";
import { NotFoundError } from "../utilities/RoutingErrors";


export default function GalleryScreen() {

    const match = useMatch(Paths.Gallery)

    if (!match) throw new NotFoundError();
    const { token } = match.params
    if (!token) throw new NotFoundError();

    return <Routes>
        <Route path={Paths.Gallery} element={<GalleryScreen />} />
    </Routes>

}