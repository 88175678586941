import buildRoll from 'src/utilities/buildRoll';
import WatchEndpoint from './WatchEndpoint';
import {
  AlbumDocument,
  AlbumQuery,
  AlbumQueryVariables,
  PhotoFullFragment,
} from './WatchEndpoint/types.generated';

declare module 'src/utilities/Services' {
  export interface ServicesList {
    deploy: DeployService;
  }
}

type DeployServiceConfig = {};

type DeployServiceDeps = {
  watch: WatchEndpoint;
};

export default class DeployService {
  private watch: WatchEndpoint;

  constructor(config: DeployServiceConfig, deps: DeployServiceDeps) {
    this.watch = deps.watch;
  }

  async deployPhotoCreation(event: string, photo: PhotoFullFragment) {
    // In album
    this.watch.client.cache.updateQuery<AlbumQuery, AlbumQueryVariables>(
      {query: AlbumDocument, variables: {event}},
      data => {
        if (!data) {
          return null;
        }
        return {
          album: buildRoll([photo, ...data.album]),
        };
      },
    );
  }

  async deployPhotoDisabling(id: string) {
    const cache = this.watch.client.cache;
    const graphId = cache.identify({__typename: 'Photo', id});
    cache.evict({id: graphId});
    cache.gc();
  }
}
