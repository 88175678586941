import {
  ApolloClient,
  BaseMutationOptions,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from '@apollo/client';
import {GraphQLWsLink} from '@apollo/client/link/subscriptions';
import {getMainDefinition} from '@apollo/client/utilities';
import {createClient} from 'graphql-ws';

import {
  useAlbumQuery,
  useEventQuery,
  useOnPhotoCreatedSubscription,
  useOnPhotoDisabledSubscription,
} from './types.generated';

declare module 'src/utilities/Services' {
  export interface ServicesList {
    watch: WatchEndpoint;
  }
}

type WatchEndpointConfig = {
  http_url: string;
  ws_url: string;
};

type WatchEndpointDeps = {};

export default class WatchEndpoint {
  private config: WatchEndpointConfig;
  private deps: WatchEndpointDeps;
  readonly client: ApolloClient<NormalizedCacheObject>;

  constructor(config: WatchEndpointConfig, deps: WatchEndpointDeps) {
    this.config = config;
    this.deps = deps;

    const httpLink = createHttpLink({
      uri: this.config.http_url,
    });

    const cache = new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            myRoll: {merge: (e, i) => [...i]},
            album: {merge: (e, i) => [...i]},
          },
        },
      },
    });

    const wsLink = new GraphQLWsLink(
      createClient({
        url: config.ws_url,
      }),
    );

    const fullHttpLink = httpLink;

    const splitLink = split(
      ({query}) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      fullHttpLink,
    );

    this.client = new ApolloClient({
      cache,
      link: splitLink,
    });
  }

  init() {
    return this;
  }

  useEventQuery = useEventQuery;
  useAlbumQuery = useAlbumQuery;
  useOnPhotoCreatedSubscription = useOnPhotoCreatedSubscription;
  useOnPhotoDisabledSubscription = useOnPhotoDisabledSubscription;
}

export type MutationOptionsData<TOptions extends BaseMutationOptions> =
  TOptions extends BaseMutationOptions<infer TData, any, any, any>
    ? TData
    : never;

export type MutationOptionsVariables<TOptions extends BaseMutationOptions> =
  TOptions extends BaseMutationOptions<any, infer TVariables, any, any>
    ? TVariables
    : never;
