import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from './hooks';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: any;
  /** Null value */
  Null: any;
};

export type Event = {
  __typename?: 'Event';
  counter: Scalars['Int'];
  id: Scalars['String'];
  invitation_token: Scalars['String'];
  name: Scalars['String'];
  roll: Array<Photo>;
};

export type LoginMutationOutput = {
  __typename?: 'LoginMutationOutput';
  token: Scalars['String'];
  user: User;
};

export type Mutation = {
  __typename?: 'Mutation';
  _?: Maybe<Scalars['String']>;
  createEvent: Event;
  createPhoto: PhotoSlot;
  deleteAccount?: Maybe<Scalars['Null']>;
  disablePhoto?: Maybe<Scalars['Null']>;
  joinEvent: Participation;
  login: LoginMutationOutput;
};


export type MutationCreateEventArgs = {
  name: Scalars['String'];
};


export type MutationCreatePhotoArgs = {
  event: Scalars['String'];
  local_key: Scalars['String'];
};


export type MutationDisablePhotoArgs = {
  photo: Scalars['String'];
};


export type MutationJoinEventArgs = {
  invitation_token: Scalars['String'];
};


export type MutationLoginArgs = {
  name: Scalars['String'];
};

export type OnPhotoCreatedPayload = {
  __typename?: 'OnPhotoCreatedPayload';
  counter: Scalars['Int'];
  photo: Photo;
};

export type OnPhotoDisabledPayload = {
  __typename?: 'OnPhotoDisabledPayload';
  counter: Scalars['Int'];
  photo: Scalars['String'];
};

export type Participation = {
  __typename?: 'Participation';
  event: Event;
  id: Scalars['String'];
  last_photo?: Maybe<Photo>;
  ref_event: Scalars['String'];
  ref_user: Scalars['String'];
  user: User;
};

export type Photo = {
  __typename?: 'Photo';
  blurhash: Scalars['String'];
  creation: Scalars['Date'];
  id: Scalars['String'];
  index: Scalars['Int'];
  local_key: Scalars['String'];
  original: Scalars['String'];
  ref_participation: Scalars['String'];
  thumbnail: Scalars['String'];
};

export type PhotoSlot = {
  __typename?: 'PhotoSlot';
  creation: Scalars['Int'];
  id: Scalars['String'];
  index: Scalars['Int'];
  ref_participation: Scalars['String'];
  upload: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  _?: Maybe<Scalars['String']>;
  album: Array<Photo>;
  events: Array<Event>;
  myRoll: Array<Photo>;
  participation: Participation;
  photo: Photo;
};


export type QueryAlbumArgs = {
  event: Scalars['String'];
};


export type QueryMyRollArgs = {
  event: Scalars['String'];
};


export type QueryParticipationArgs = {
  event: Scalars['String'];
};


export type QueryPhotoArgs = {
  photo: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  _?: Maybe<Scalars['String']>;
  onPhotoCreated: OnPhotoCreatedPayload;
  onPhotoDisabled: OnPhotoDisabledPayload;
};


export type SubscriptionOnPhotoCreatedArgs = {
  event: Scalars['String'];
};


export type SubscriptionOnPhotoDisabledArgs = {
  event: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type AlbumQueryVariables = Exact<{
  event: Scalars['String'];
}>;


export type AlbumQuery = { __typename?: 'Query', album: Array<{ __typename?: 'Photo', id: string, thumbnail: string, original: string, blurhash: string, index: number, local_key: string, creation: any }> };

export type CreateEventMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateEventMutation = { __typename?: 'Mutation', createEvent: { __typename?: 'Event', id: string, name: string, invitation_token: string, roll: Array<{ __typename?: 'Photo', id: string, thumbnail: string, blurhash: string, index: number }> } };

export type CreatePhotoMutationVariables = Exact<{
  event: Scalars['String'];
  local_key: Scalars['String'];
}>;


export type CreatePhotoMutation = { __typename?: 'Mutation', createPhoto: { __typename?: 'PhotoSlot', id: string, index: number, upload: string } };

export type DeleteAccountMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteAccountMutation = { __typename?: 'Mutation', deleteAccount?: any | null };

export type DisablePhotoMutationVariables = Exact<{
  photo: Scalars['String'];
}>;


export type DisablePhotoMutation = { __typename?: 'Mutation', disablePhoto?: any | null };

export type EventsQueryVariables = Exact<{ [key: string]: never; }>;


export type EventsQuery = { __typename?: 'Query', events: Array<{ __typename?: 'Event', id: string, name: string, invitation_token: string, roll: Array<{ __typename?: 'Photo', id: string, thumbnail: string, blurhash: string, index: number }> }> };

export type JoinEventMutationVariables = Exact<{
  invitation_token: Scalars['String'];
}>;


export type JoinEventMutation = { __typename?: 'Mutation', joinEvent: { __typename?: 'Participation', id: string, event: { __typename?: 'Event', id: string, name: string, invitation_token: string, roll: Array<{ __typename?: 'Photo', id: string, thumbnail: string, blurhash: string, index: number }> } } };

export type LoginMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginMutationOutput', token: string, user: { __typename?: 'User', id: string, name: string } } };

export type MyRollQueryVariables = Exact<{
  event: Scalars['String'];
}>;


export type MyRollQuery = { __typename?: 'Query', myRoll: Array<{ __typename?: 'Photo', id: string, thumbnail: string, original: string, blurhash: string, index: number, local_key: string, creation: any }> };

export type OnPhotoCreatedSubscriptionVariables = Exact<{
  event: Scalars['String'];
}>;


export type OnPhotoCreatedSubscription = { __typename?: 'Subscription', onPhotoCreated: { __typename?: 'OnPhotoCreatedPayload', counter: number, photo: { __typename?: 'Photo', id: string, thumbnail: string, original: string, blurhash: string, index: number, local_key: string, creation: any } } };

export type OnPhotoDisabledSubscriptionVariables = Exact<{
  event: Scalars['String'];
}>;


export type OnPhotoDisabledSubscription = { __typename?: 'Subscription', onPhotoDisabled: { __typename?: 'OnPhotoDisabledPayload', photo: string, counter: number } };

export type ParticipationQueryVariables = Exact<{
  event: Scalars['String'];
}>;


export type ParticipationQuery = { __typename?: 'Query', participation: { __typename?: 'Participation', id: string, event: { __typename?: 'Event', id: string, name: string, invitation_token: string, counter: number }, last_photo?: { __typename?: 'Photo', id: string, thumbnail: string, original: string, blurhash: string, index: number, local_key: string, creation: any } | null } };

export type PhotoQueryVariables = Exact<{
  photo: Scalars['String'];
}>;


export type PhotoQuery = { __typename?: 'Query', photo: { __typename?: 'Photo', id: string, thumbnail: string, original: string, blurhash: string, index: number, local_key: string, creation: any } };

export type EventCounterFragment = { __typename?: 'Event', counter: number };

export type PhotoFullFragment = { __typename?: 'Photo', id: string, thumbnail: string, original: string, blurhash: string, index: number, local_key: string, creation: any };

export type PhotoReferenceFragment = { __typename?: 'Photo', id: string, thumbnail: string, blurhash: string, index: number };

export type PhotoSlotFullFragment = { __typename?: 'PhotoSlot', id: string, index: number, upload: string };

export const EventCounterFragmentDoc = gql`
    fragment EventCounter on Event {
  counter
}
    `;
export const PhotoFullFragmentDoc = gql`
    fragment PhotoFull on Photo {
  id
  thumbnail
  original
  blurhash
  index
  local_key
  creation
}
    `;
export const PhotoReferenceFragmentDoc = gql`
    fragment PhotoReference on Photo {
  id
  thumbnail
  blurhash
  index
}
    `;
export const PhotoSlotFullFragmentDoc = gql`
    fragment PhotoSlotFull on PhotoSlot {
  id
  index
  upload
}
    `;
export const AlbumDocument = gql`
    query Album($event: String!) {
  album(event: $event) {
    ...PhotoFull
  }
}
    ${PhotoFullFragmentDoc}`;

/**
 * __useAlbumQuery__
 *
 * To run a query within a React component, call `useAlbumQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlbumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlbumQuery({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useAlbumQuery(baseOptions: ApolloReactHooks.QueryHookOptions<AlbumQuery, AlbumQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<AlbumQuery, AlbumQueryVariables>(AlbumDocument, options);
      }
export function useAlbumLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AlbumQuery, AlbumQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<AlbumQuery, AlbumQueryVariables>(AlbumDocument, options);
        }
export type AlbumQueryHookResult = ReturnType<typeof useAlbumQuery>;
export type AlbumLazyQueryHookResult = ReturnType<typeof useAlbumLazyQuery>;
export type AlbumQueryResult = Apollo.QueryResult<AlbumQuery, AlbumQueryVariables>;
export const CreateEventDocument = gql`
    mutation CreateEvent($name: String!) {
  createEvent(name: $name) {
    id
    name
    invitation_token
    roll {
      ...PhotoReference
    }
  }
}
    ${PhotoReferenceFragmentDoc}`;
export type CreateEventMutationFn = Apollo.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, options);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const CreatePhotoDocument = gql`
    mutation CreatePhoto($event: String!, $local_key: String!) {
  createPhoto(event: $event, local_key: $local_key) {
    ...PhotoSlotFull
  }
}
    ${PhotoSlotFullFragmentDoc}`;
export type CreatePhotoMutationFn = Apollo.MutationFunction<CreatePhotoMutation, CreatePhotoMutationVariables>;

/**
 * __useCreatePhotoMutation__
 *
 * To run a mutation, you first call `useCreatePhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPhotoMutation, { data, loading, error }] = useCreatePhotoMutation({
 *   variables: {
 *      event: // value for 'event'
 *      local_key: // value for 'local_key'
 *   },
 * });
 */
export function useCreatePhotoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePhotoMutation, CreatePhotoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreatePhotoMutation, CreatePhotoMutationVariables>(CreatePhotoDocument, options);
      }
export type CreatePhotoMutationHookResult = ReturnType<typeof useCreatePhotoMutation>;
export type CreatePhotoMutationResult = Apollo.MutationResult<CreatePhotoMutation>;
export type CreatePhotoMutationOptions = Apollo.BaseMutationOptions<CreatePhotoMutation, CreatePhotoMutationVariables>;
export const DeleteAccountDocument = gql`
    mutation DeleteAccount {
  deleteAccount
}
    `;
export type DeleteAccountMutationFn = Apollo.MutationFunction<DeleteAccountMutation, DeleteAccountMutationVariables>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAccountMutation, DeleteAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DeleteAccountDocument, options);
      }
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = Apollo.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<DeleteAccountMutation, DeleteAccountMutationVariables>;
export const DisablePhotoDocument = gql`
    mutation DisablePhoto($photo: String!) {
  disablePhoto(photo: $photo)
}
    `;
export type DisablePhotoMutationFn = Apollo.MutationFunction<DisablePhotoMutation, DisablePhotoMutationVariables>;

/**
 * __useDisablePhotoMutation__
 *
 * To run a mutation, you first call `useDisablePhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisablePhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disablePhotoMutation, { data, loading, error }] = useDisablePhotoMutation({
 *   variables: {
 *      photo: // value for 'photo'
 *   },
 * });
 */
export function useDisablePhotoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DisablePhotoMutation, DisablePhotoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DisablePhotoMutation, DisablePhotoMutationVariables>(DisablePhotoDocument, options);
      }
export type DisablePhotoMutationHookResult = ReturnType<typeof useDisablePhotoMutation>;
export type DisablePhotoMutationResult = Apollo.MutationResult<DisablePhotoMutation>;
export type DisablePhotoMutationOptions = Apollo.BaseMutationOptions<DisablePhotoMutation, DisablePhotoMutationVariables>;
export const EventsDocument = gql`
    query Events {
  events {
    id
    name
    invitation_token
    roll {
      ...PhotoReference
    }
  }
}
    ${PhotoReferenceFragmentDoc}`;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEventsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventsQuery, EventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
      }
export function useEventsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
        }
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsQueryResult = Apollo.QueryResult<EventsQuery, EventsQueryVariables>;
export const JoinEventDocument = gql`
    mutation JoinEvent($invitation_token: String!) {
  joinEvent(invitation_token: $invitation_token) {
    id
    event {
      id
      name
      invitation_token
      roll {
        ...PhotoReference
      }
    }
  }
}
    ${PhotoReferenceFragmentDoc}`;
export type JoinEventMutationFn = Apollo.MutationFunction<JoinEventMutation, JoinEventMutationVariables>;

/**
 * __useJoinEventMutation__
 *
 * To run a mutation, you first call `useJoinEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinEventMutation, { data, loading, error }] = useJoinEventMutation({
 *   variables: {
 *      invitation_token: // value for 'invitation_token'
 *   },
 * });
 */
export function useJoinEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JoinEventMutation, JoinEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<JoinEventMutation, JoinEventMutationVariables>(JoinEventDocument, options);
      }
export type JoinEventMutationHookResult = ReturnType<typeof useJoinEventMutation>;
export type JoinEventMutationResult = Apollo.MutationResult<JoinEventMutation>;
export type JoinEventMutationOptions = Apollo.BaseMutationOptions<JoinEventMutation, JoinEventMutationVariables>;
export const LoginDocument = gql`
    mutation Login($name: String!) {
  login(name: $name) {
    token
    user {
      id
      name
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const MyRollDocument = gql`
    query MyRoll($event: String!) {
  myRoll(event: $event) {
    ...PhotoFull
  }
}
    ${PhotoFullFragmentDoc}`;

/**
 * __useMyRollQuery__
 *
 * To run a query within a React component, call `useMyRollQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyRollQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyRollQuery({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useMyRollQuery(baseOptions: ApolloReactHooks.QueryHookOptions<MyRollQuery, MyRollQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<MyRollQuery, MyRollQueryVariables>(MyRollDocument, options);
      }
export function useMyRollLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyRollQuery, MyRollQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<MyRollQuery, MyRollQueryVariables>(MyRollDocument, options);
        }
export type MyRollQueryHookResult = ReturnType<typeof useMyRollQuery>;
export type MyRollLazyQueryHookResult = ReturnType<typeof useMyRollLazyQuery>;
export type MyRollQueryResult = Apollo.QueryResult<MyRollQuery, MyRollQueryVariables>;
export const OnPhotoCreatedDocument = gql`
    subscription OnPhotoCreated($event: String!) {
  onPhotoCreated(event: $event) {
    photo {
      ...PhotoFull
    }
    counter
  }
}
    ${PhotoFullFragmentDoc}`;

/**
 * __useOnPhotoCreatedSubscription__
 *
 * To run a query within a React component, call `useOnPhotoCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnPhotoCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnPhotoCreatedSubscription({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useOnPhotoCreatedSubscription(baseOptions: ApolloReactHooks.SubscriptionHookOptions<OnPhotoCreatedSubscription, OnPhotoCreatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<OnPhotoCreatedSubscription, OnPhotoCreatedSubscriptionVariables>(OnPhotoCreatedDocument, options);
      }
export type OnPhotoCreatedSubscriptionHookResult = ReturnType<typeof useOnPhotoCreatedSubscription>;
export type OnPhotoCreatedSubscriptionResult = Apollo.SubscriptionResult<OnPhotoCreatedSubscription>;
export const OnPhotoDisabledDocument = gql`
    subscription OnPhotoDisabled($event: String!) {
  onPhotoDisabled(event: $event) {
    photo
    counter
  }
}
    `;

/**
 * __useOnPhotoDisabledSubscription__
 *
 * To run a query within a React component, call `useOnPhotoDisabledSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnPhotoDisabledSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnPhotoDisabledSubscription({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useOnPhotoDisabledSubscription(baseOptions: ApolloReactHooks.SubscriptionHookOptions<OnPhotoDisabledSubscription, OnPhotoDisabledSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<OnPhotoDisabledSubscription, OnPhotoDisabledSubscriptionVariables>(OnPhotoDisabledDocument, options);
      }
export type OnPhotoDisabledSubscriptionHookResult = ReturnType<typeof useOnPhotoDisabledSubscription>;
export type OnPhotoDisabledSubscriptionResult = Apollo.SubscriptionResult<OnPhotoDisabledSubscription>;
export const ParticipationDocument = gql`
    query Participation($event: String!) {
  participation(event: $event) {
    id
    event {
      id
      name
      invitation_token
      counter
    }
    last_photo {
      ...PhotoFull
    }
  }
}
    ${PhotoFullFragmentDoc}`;

/**
 * __useParticipationQuery__
 *
 * To run a query within a React component, call `useParticipationQuery` and pass it any options that fit your needs.
 * When your component renders, `useParticipationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParticipationQuery({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useParticipationQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ParticipationQuery, ParticipationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ParticipationQuery, ParticipationQueryVariables>(ParticipationDocument, options);
      }
export function useParticipationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ParticipationQuery, ParticipationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ParticipationQuery, ParticipationQueryVariables>(ParticipationDocument, options);
        }
export type ParticipationQueryHookResult = ReturnType<typeof useParticipationQuery>;
export type ParticipationLazyQueryHookResult = ReturnType<typeof useParticipationLazyQuery>;
export type ParticipationQueryResult = Apollo.QueryResult<ParticipationQuery, ParticipationQueryVariables>;
export const PhotoDocument = gql`
    query Photo($photo: String!) {
  photo(photo: $photo) {
    ...PhotoFull
  }
}
    ${PhotoFullFragmentDoc}`;

/**
 * __usePhotoQuery__
 *
 * To run a query within a React component, call `usePhotoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhotoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhotoQuery({
 *   variables: {
 *      photo: // value for 'photo'
 *   },
 * });
 */
export function usePhotoQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PhotoQuery, PhotoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PhotoQuery, PhotoQueryVariables>(PhotoDocument, options);
      }
export function usePhotoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PhotoQuery, PhotoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PhotoQuery, PhotoQueryVariables>(PhotoDocument, options);
        }
export type PhotoQueryHookResult = ReturnType<typeof usePhotoQuery>;
export type PhotoLazyQueryHookResult = ReturnType<typeof usePhotoLazyQuery>;
export type PhotoQueryResult = Apollo.QueryResult<PhotoQuery, PhotoQueryVariables>;