import React, { ReactNode, useContext } from 'react';

export interface ServicesList { }

const ServiceContext = React.createContext<ServicesList | null>(null);

type ProviderProps = {
  services: ServicesList;
  children: ReactNode;
};

function Provider(props: ProviderProps) {
  return (
    <ServiceContext.Provider value={props.services}>
      {props.children}
    </ServiceContext.Provider>
  );
}

function use() {
  const services = useContext(ServiceContext);
  if (!services) throw new Error('Services are not loaded');
  return services;
}

const Services = {
  Provider,
  use,
};

export default Services;
