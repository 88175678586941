/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { AnyError } from 'src/utilities/Errors';
import Typo from './Typo';

type ErrorViewProps = {
    error: AnyError;
};

export default function ErrorView(props: ErrorViewProps) {

    const containerCss = css``

    return (
        <div css={containerCss}>
            <Typo center>Oups :/</Typo>
        </div>
    );
}

