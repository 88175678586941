/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { DataSet } from "@hoovee-party/library";
import { difference, random, sample } from "lodash";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useMatch } from "react-router-dom";
import { useWakeLock } from 'react-screen-wake-lock';
import AsyncValueView from "src/components/AsyncValueView";
import AsyncValue, { Async } from "src/utilities/AsyncValue";
import Model from "src/utilities/Model";
import Paths from "src/utilities/Paths";
import Services from "src/utilities/Services";
import Updater from "src/utilities/Updater";

export default function SlideshowScreen() {

    const params = useMatch(Paths.Slideshow);
    if (!params) throw new Error("404");
    const eventId = params.params.event
    if (!eventId) throw new Error("404");

    const { watch, deploy } = Services.use()



    const { isSupported, released, request, release } = useWakeLock({
        onRequest: () => alert('Screen Wake Lock: requested!'),
        onError: () => alert('An error happened 💥'),
        onRelease: () => alert('Screen Wake Lock: released!'),
    })

    useEffect(() => {
        if (!isSupported) return;
        request("screen")
        console.log("Wake lock ok");;
        return (() => {
            release();
        })
    }, [isSupported])

    const eventResult = watch.useEventQuery({ variables: { event: eventId } })
    const eventValue = AsyncValue.useQueryResult(eventResult, (p) => p.event)

    const albumResult = watch.useAlbumQuery({ variables: { event: eventId } })
    const albumValue = AsyncValue.useQueryResult(albumResult, (p) => p.album)


    watch.useOnPhotoCreatedSubscription({
        variables: { event: eventId }, onSubscriptionData: (d) => {
            const data = d.subscriptionData.data
            if (!data) return;
            const photo = data.onPhotoCreated.photo
            deploy.deployPhotoCreation(eventId, photo);
        }
    })

    watch.useOnPhotoDisabledSubscription({
        variables: { event: eventId }, onSubscriptionData: (d) => {
            const data = d.subscriptionData.data
            if (!data) return;
            const photo = data.onPhotoDisabled.photo
            deploy.deployPhotoDisabling(photo);
        }
    })

    return (<AsyncValueView value={eventValue} renderValue={(event) => {
        return <AsyncValueView value={albumValue} renderValue={(album) => {
            return (<Slideshow event={event} album={album} />)
        }} />
    }} />)
}

type SlideshowProps = {
    event: Model.Event
    album: Array<Model.Photo>
}

type SlideBase = { id: number }




type Slide = PhotoSlide;

function Slideshow(props: SlideshowProps) {

    const { event, album } = props;

    const photoIds = album.map(p => p.id)

    useEffect(() => {
        Views.ensureOneView(photoIds);
    }, [])

    useEffect(() => {
        album.forEach(p => PhotosCache.load(p.id, p.original))
    }, [photoIds.join(" ")])

    const loadedPhotos = PhotosCache.usePhotosLocalUrl(album.map(p => p.id))

    const [recents, setRecents] = useState<Array<string>>([]);
    const [slides, setSlides] = useState<Array<Slide>>([]);

    useEffect(() => {
        const toRemove = slides.filter(s => s.io === "left")
        if (toRemove.length === 0) return
        setSlides(difference(slides, toRemove))
    }, [slides])

    useEffect(() => {
        const diffusedSlide = slides.find(s => (s.io === "initialized" || s.io === "appearing" || s.io === "visible"))
        if (!diffusedSlide) return;
        const inAlbum = album.find(p => p.id === diffusedSlide.photo.id)
        if (inAlbum) return;
        const newSlides = slides.map(s => s === diffusedSlide ? ({ ...s, io: "leaving" } as PhotoSlide) : s)
        setSlides(newSlides)
    }, [slides, album])

    useEffect(() => {

        const current = slides.find(s => {
            if (s.type === "PhotoSlide") {
                return (s.io === "initialized" || s.io === "appearing" || s.io === "visible")
            } else if (s.type === "EmptySlide") {
                return false
            } else {
                return (false);
            }
        });

        if (current) {
            return;
        }

        const newSlideId = Date.now()

        const cachedIds = Object.keys(loadedPhotos);
        let elligibles = album.filter(p => cachedIds.includes(p.id))
        elligibles = elligibles.filter(p => !recents.includes(p.id))

        if (elligibles.length === 0) return

        const newPhoto = elligibles.find(p => Views.getViews(p.id) === 0) || null

        const duration = newPhoto ? 10000 : 5000;

        let elected: Model.Photo | null = newPhoto;
        if (elected === null) {
            const maxViews = Math.max(...elligibles.map(p => Views.getViews(p.id)));
            const bucket: Array<Model.Photo> = []
            elligibles.forEach((photo) => {
                const views = Views.getViews(photo.id);
                const nbInBucket = (maxViews + 1) - views;
                for (let i = 0; i < nbInBucket; i++) {
                    bucket.push(photo);
                }
            })
            elected = sample(bucket) || null;
        }
        if (elected === null) return;


        Views.registerView(elected.id);

        const newSlide: PhotoSlide = {
            id: newSlideId,
            io: "initialized",
            type: "PhotoSlide",
            photo: elected,
            duration,
            local: loadedPhotos[elected.id],
            onIoChange: (io => setSlides(c => {
                const current = DataSet.search(c, newSlideId) as PhotoSlide | null;
                if (!current) return c;
                return DataSet.replace(c, { ...current, io })
            }))
        }

        let keptInRecent = (cachedIds.length <= 5) ? cachedIds.length - 1 : 5;
        if (keptInRecent < 0) keptInRecent = 0;
        const electedId = elected.id
        setRecents(r => [electedId, ...r].slice(0, keptInRecent))

        setSlides([
            ...slides,
            newSlide
        ])



    }, [slides, album, loadedPhotos, recents])

    const containerCss = css`
        width : 100vw ;
        height : 100vh ;
        position: relative ;
    `

    return (<div css={containerCss}>
        {slides.map(slide => {
            if (slide.type === "PhotoSlide") return (<PhotoSlideView key={slide.id} {...slide} />)
            else return (<Fragment key={0} />)
        })}
    </div>)
}


const viewsUpdater = new Updater();
const stored = localStorage.getItem("views")
const viewsState: { [key: string]: number } = stored ? JSON.parse(stored) : {};

function store() {
    localStorage.setItem("views", JSON.stringify(viewsState))
}

function registerView(id: string) {
    if (viewsState[id] === undefined) viewsState[id] = 1;
    else viewsState[id]++
    viewsUpdater.update();
    store();
}

function getViews(id: string) {
    return viewsState[id] || 0
}

function ensureOneView(ids: Array<string>) {
    ids.forEach((id) => {
        const views = viewsState[id];
        if (views === undefined) viewsState[id] = 1;
    })
    store();
}

const Views = {
    registerView,
    getViews,
    ensureOneView
}


const photosCacheUpdater = new Updater();
const photosCache: { [id: string]: Async<string> } = {}

const PhotosCache = {
    load: (id: string, remote: string) => {
        const inCache = photosCache[id];
        if (inCache) return;
        else PhotosCache.download(id, remote);
    },
    download: async (id: string, remote: string) => {
        console.log("Downloading", id);
        photosCache[id] = { loading: true }
        photosCacheUpdater.update();
        try {
            const output = await fetch(remote)
            if (output.ok) {
                const blob = await output.blob();
                const url = URL.createObjectURL(blob);
                photosCache[id] = { loading: false, ok: true, value: url }
                photosCacheUpdater.update();
            } else {
                photosCache[id] = { loading: false, ok: false, error: "Fetch !ok" }
                photosCacheUpdater.update();
            }
        } catch (err) {
            photosCache[id] = { loading: false, ok: false, error: err }
            photosCacheUpdater.update();
        }
    },
    usePhotosLocalUrl: (ids: Array<string>) => {
        return (photosCacheUpdater.useValue(() => {
            const output: Record<string, string> = {};
            ids.forEach(id => {
                const stored = photosCache[id];
                if (!stored) return;
                if (AsyncValue.isResolved(stored))
                    output[id] = stored.value;
            })
            return output;
        }, [ids.join(" ")]))
    }
}





type PhotoSlideIo = "initialized" | "appearing" | "visible" | "leaving" | "left"

type PhotoSlide = SlideBase & {
    type: "PhotoSlide"
    duration: number,
    io: "initialized" | "appearing" | "visible" | "leaving" | "left"
    onIoChange: (io: PhotoSlideIo) => any
    photo: Model.Photo,
    local: string
}

function PhotoSlideView(props: PhotoSlide) {
    const { io, duration, local, onIoChange } = props

    useEffect(() => {
        if (io === "initialized") {
            setTimeout(() => {
                onIoChange("appearing")
            }, 10);
        } else if (io === "appearing") {
            setTimeout(() => {
                onIoChange("visible")
            }, 1000);
        } else if (io === "visible") {
            setTimeout(() => {
                onIoChange("leaving")
            }, duration);
        } else if (io === "leaving") {
            setTimeout(() => {
                onIoChange("left")
            }, 1000);
        }
    }, [io])


    const visible = io === "appearing" || io === "visible"

    const containerCss = css`
    position : absolute ;
    top : 0px ;
    left : 0px ;
    right : 0px ;
    bottom : 0px ;
    display : flex ;
    align-items : center ;
    justify-content : center ;
    opacity : ${visible ? 1 : 0} ;
    transition : opacity 1s ;
`

    const unit = window.innerWidth > window.innerHeight ? "vh" : "vw";
    const rotation = useMemo(() => random(-15, 15), [])

    const imageCss = css`
    width : 90${unit} ;
    height : 90${unit} ;
    position : relative ;
    zIndex : 2 ;
    box-shadow :0px 0px 10${unit} rgba(0,0,0,0.5) ;
    transform : rotate(${rotation}deg) ;
`

    const backgroundCss = css`position : absolute ;
        top : -10${unit} ;
        left : -10${unit} ;
        right : -10${unit} ;
        bottom : -10${unit} ;
        background-image : url(${local}) ;
        background-size : cover ;
        background-position : center ;
        filter : blur(100px) ;

    `

    return (<div css={containerCss}>
        <div css={backgroundCss} />
        <img src={local} css={imageCss} alt="" />
    </div>)
}