import React, { Fragment, ReactNode } from 'react';
import AsyncValue, { MaybeAsync } from 'src/utilities/AsyncValue';
import { AnyError } from 'src/utilities/Errors';
import ErrorView from './ErrorView';
import LoadingView from './LoadingView';

type LoadingComponentType = React.ComponentType<any>;
type ErrorComponentType = React.ComponentType<{ error: AnyError }>;

type AsyncValueViewProps<TValue> = {
    value: MaybeAsync<TValue>;
    forceLoading?: boolean;
    LoadingComponent?: LoadingComponentType | null;
    ErrorComponent?: React.ComponentType<{ error: AnyError }> | null;
    renderValue: (value: TValue) => ReactNode;
};

export default function AsyncValueView<TValue>(
    props: AsyncValueViewProps<TValue>,
) {
    const { value, forceLoading, LoadingComponent, ErrorComponent, renderValue } =
        props;

    const s = AsyncValue.useWrapper(value);

    let Loading: LoadingComponentType | null = null;
    if (LoadingComponent !== null) {
        if (LoadingComponent) {
            Loading = LoadingComponent;
        } else {
            Loading = LoadingView;
        }
    }

    let Error: ErrorComponentType | null = null;
    if (ErrorComponent !== null) {
        if (ErrorComponent) {
            Error = ErrorComponent;
        } else {
            Error = ErrorView;
        }
    }

    if (!AsyncValue.isEnded(s) || forceLoading) {
        if (Loading) {
            return <Loading />;
        } else {
            return <Fragment />;
        }
    } else if (AsyncValue.isRejected(s)) {
        console.log(s.error);
        if (Error) {
            return <Error error={s.error} />;
        } else {
            return <Fragment />;
        }
    } else {
        return <Fragment>{renderValue(s.value)}</Fragment>;
    }
}
