import { Route, Routes } from "react-router-dom";
import Paths from "../utilities/Paths";
import EventScreen from "./EventScreen";
import GalleryScreen from "./GalleryScreen";
import HomeScreen from "./HomeScreen";
import ShootScreen from "./ShootScreen";
import SlideshowScreen from "./SlideshowScreen";


export default function AppRouter() {
    return <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path={Paths.Shoot} element={<ShootScreen />} />
        <Route path={Paths.Events} element={<EventScreen />} />
        <Route path={Paths.Slideshow} element={<SlideshowScreen />} />
        <Route path={Paths.Gallery} element={<GalleryScreen />} />
    </Routes>

}