import {css} from '@emotion/react';

const body = css`
  font-family: 'Outfit-Regular';
  font-size: 1rem;
  line-height: 1;
  text-transform: none;
`;

const title = css`
  font-family: 'Outfit-SemiBold';
  font-size: 1.5rem;
  line-height: 1;
  text-transform: none;
`;

const button = css`
  font-family: 'Outfit-Bold';
  font-size: 1.1rem;
  line-height: 1;
  text-transform: uppercase;
`;

const Typos = {body, button, title};

export type TypoName = keyof typeof Typos;

export default Typos;
