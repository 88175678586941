import {QueryResult} from '@apollo/client';
import React from 'react';
import {AnyError} from './Errors';

export type AsyncCallback<TReturn = any> = () => Promise<TReturn>;
type LoadingAsync = {loading: true};
type ResolvedAsync<TValue> = {loading: false; ok: true; value: TValue};
type RejectedAsync = {loading: false; ok: false; error: AnyError};

/**
 * Système d'état lié à un traitement asynchrone
 */
export type Async<TValue> =
  | LoadingAsync
  | ResolvedAsync<TValue>
  | RejectedAsync;

export type MaybeAsync<TValue> = TValue | Async<TValue>;

export type Value<TInput> = TInput extends Async<infer T> ? T : TInput;

// Générateurs

function loading(): LoadingAsync {
  return {loading: true};
}
function resolved<TValue>(v: TValue): ResolvedAsync<TValue> {
  return {loading: false, ok: true, value: v};
}

function rejected(error: AnyError): RejectedAsync {
  return {loading: false, ok: false, error: error};
}

// Type guards

function isLoading<TValue>(state: Async<TValue>): state is LoadingAsync {
  return state.loading === true;
}

function isEnded<TValue>(
  state: Async<TValue>,
): state is ResolvedAsync<TValue> | RejectedAsync {
  return state.loading === false;
}

function isResolved<TValue>(
  state: Async<TValue>,
): state is ResolvedAsync<TValue> {
  return state.loading === false && state.ok === true;
}

function isRejected(state: Async<any>): state is RejectedAsync {
  return state.loading === false && state.ok === false;
}

// Utils

function useWrapper<TValue>(input: MaybeAsync<TValue>): Async<TValue> {
  if ('loading' in input) {
    return input as Async<TValue>;
  } else {
    return resolved(input);
  }
}

function wrap<TValue>(input: MaybeAsync<TValue>): Async<TValue> {
  if ('loading' in input) {
    return input as Async<TValue>;
  } else {
    return resolved(input);
  }
}

// Builders

function useQueryResult<TData, TOutput>(
  queryResult: QueryResult<TData, any>,
  filter: (data: TData) => TOutput,
): Async<TOutput> {
  const {called, loading: running, data, error} = queryResult;
  return React.useMemo(() => {
    if (!called || running) {
      return loading();
    } else if (error) {
      return rejected(error);
    } else if (data) {
      return resolved(filter(data));
    } else {
      throw new Error('Error in query result');
    }
  }, [called, loading, data, error]);
}

/**
 * Traduit les appels asynchrones en systèmes d'état
 */
const AsyncValue = {
  loading,
  resolved,
  rejected,
  isEnded,
  isResolved,
  isRejected,
  useQueryResult,
  useWrapper,
};

export default AsyncValue;
