import * as Apollo from '@apollo/client';
import {
  DocumentNode,
  LazyQueryHookOptions,
  MutationHookOptions,
  QueryHookOptions,
  SubscriptionHookOptions
} from '@apollo/client';
import Services from 'src/utilities/Services';

export function useQuery<TData, TVariables>(
  document: DocumentNode,
  options: QueryHookOptions<TData, TVariables> = {},
) {
  const opts: QueryHookOptions<TData, TVariables> = {
    ...options,
    client: Services.use().enjoy.client,
  };
  return Apollo.useQuery<TData, TVariables>(document, opts);
}

export function useLazyQuery<TData, TVariables>(
  document: DocumentNode,
  options: LazyQueryHookOptions<TData, TVariables> = {},
) {
  const opts: LazyQueryHookOptions<TData, TVariables> = {
    ...options,
    client: Services.use().enjoy.client,
  };
  return Apollo.useLazyQuery<TData, TVariables>(document, opts);
}

export function useMutation<TData, TVariables>(
  document: DocumentNode,
  options: MutationHookOptions<TData, TVariables> = {},
) {
  const opts: MutationHookOptions<TData, TVariables> = {
    ...options,
    client: Services.use().enjoy.client,
  };
  return Apollo.useMutation<TData, TVariables>(document, opts);
}

export function useSubscription<TData, TVariables>(
  document: DocumentNode,
  options: SubscriptionHookOptions<TData, TVariables> = {},
) {
  const opts: SubscriptionHookOptions<TData, TVariables> = {
    ...options,
    client: Services.use().enjoy.client,
  };
  return Apollo.useSubscription<TData, TVariables>(document, opts);
}

export type {
  MutationHookOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  SubscriptionHookOptions,
};
