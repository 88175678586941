/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { nanoid } from "nanoid";
import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useMatch } from "react-router-dom";
import AsyncValueView from "src/components/AsyncValueView";
import LoadingView from "src/components/LoadingView";
import Typo from "src/components/Typo";
import { useLoginMutation } from "src/services/EnjoyEndpoint/types.generated";
import AsyncValue from "src/utilities/AsyncValue";
import Paths from "src/utilities/Paths";
import Services from "src/utilities/Services";
import Typos from "src/utilities/Typos";


export default function ShootScreen() {

    const { identity } = Services.use();

    const params = useMatch(Paths.Shoot);
    if (!params) throw new Error("404");
    const eventId = params.params.event
    if (!eventId) throw new Error("404");

    const isLoggedIn = identity.useIsLoggingOut();

    let node: ReactNode = null;
    if (isLoggedIn) node = <Participation event={eventId} />
    else node = (<Name />)

    const containerCss = css`
        min-height : 100vh ;
        background : linear-gradient(to top right, #EE0979, #FF6A00);
        color : white ;
        padding : 50px 20px ;
    `

    const imageCss = css`
    width : 50% ;
    display : block ;
    margin : auto ;
    margin-bottom : 50px ;
`


    return (<div css={containerCss}>
        <img css={imageCss} src="/hoovee.svg" alt="Hoovee" />
        {node}
    </div>)


}


function Name() {

    const { identity } = Services.use();

    const [name, setName] = useState<string>("");

    const inputCss = css`
    ${Typos.title} ;
    display : block ;
    margin : auto ;
    margin-top : 20px ;
    background-color : rgba(255,255,255,0.1) ;
    border : 1px solid white ;
    border-radius : 4px ;
    padding : 10px ;
    width : 100% ;
    text-align : center ;
    color : white ;
    &:focus{
        outline : 3px solid rgba(255,255,255,0.3) ;
    }

`

    const buttonCss = css`
${Typos.button} ;
display : block ;
margin : auto ;
margin-top : 20px ;
background-color : rgba(255,255,255,1) ;
border : 1px solid white ;
border-radius : 4px ;
padding : 10px ;
text-align : center ;
color : #F73A3D ;
&:focus{
    outline : 3px solid rgba(255,255,255,0.3) ;
}
`

    const [login] = useLoginMutation();

    const onSubmit = useCallback(async (e: React.FormEvent) => {
        e.preventDefault()
        const output = await login({ variables: { name } })
        const data = output.data
        if (!data) return;
        identity.setIdentity(data.login)
    }, [])


    return (<div>
        <Typo name="title" center>Entrez votre prénom</Typo>
        <form onSubmit={onSubmit}>
            <input value={name} onChange={(e) => setName(e.target.value)} css={inputCss} autoFocus />
            <Button submit label="Continuer" />
        </form>
    </div>)
}

type ParticipationProps = {
    event: string
}

function Participation(props: ParticipationProps) {
    const { event } = props;

    const { enjoy } = Services.use();

    const events = enjoy.useEventsQuery();
    const eventsValue = AsyncValue.useQueryResult(events, (q) => q.events)


    return <AsyncValueView value={eventsValue} renderValue={events => {
        const ids = events.map(e => e.id);
        if (ids.includes(event)) return (<Camera event={event} />)
        else return (<CreateParticipation event={event} />)
    }} />


}

type CreateParticipationProps = {
    event: string
}

function CreateParticipation(props: CreateParticipationProps) {
    const { event } = props;

    const { enjoy } = Services.use();

    const [joinEvent] = enjoy.useJoinEventMutation();

    useEffect(() => {
        joinEvent({ variables: { invitation_token: `${window.location.origin}/events/${event}` } })
            .then(() => window.location.reload())
    }, [event])


    return <LoadingView />


}

type CameraProps = {
    event: string
}

function Camera(props: CameraProps) {

    const { event } = props;

    const { enjoy } = Services.use();

    const [inputProps, setInputProps] = useState<React.InputHTMLAttributes<HTMLInputElement> & { key: number } | null>(null)

    const ref = useRef<HTMLInputElement>(null)

    const [createSlot] = enjoy.useCreatePhotoMutation();

    const [message, setMessage] = useState("");

    const handlePhoto = useCallback(async (file: File) => {
        try {

            if (file.type !== "image/jpeg") return;
            setMessage("Envoi de la photo en cours...")
            const localId = nanoid();
            const slotResult = await createSlot({ variables: { event, local_key: localId } })
            const slotData = slotResult.data;
            if (!slotData) return;
            const slot = slotData.createPhoto
            await uploadFile({
                method: 'PUT',
                path: slot.upload,
                contentType: 'image/jpeg',
                file: file,
                onProgress: () => console.log("Progress")
            })
            setMessage("Photo envoyée !")
            setTimeout(() => {
                setMessage("")
            }, 1000)
        } catch (err) {
            setMessage("Votre photo n'a pas pu être envoyée :/")
            setTimeout(() => {
                setMessage("")
            }, 2000)
        }
    }, [])

    const openPicker = useCallback((capture: "user" | "environment" | undefined) => {
        setInputProps({
            key: Date.now(),
            type: "file",
            accept: "image/jpeg",
            capture: capture,
            onChange: (e) => {
                const value = e.target.files
                if (!value) return;
                const file = value.item(0)
                if (!file) return;
                handlePhoto(file);
            }
        })
        setTimeout(() => {
            if (!ref.current) return;
            ref.current.click();
        }, 10)
    }, [])

    const inputCss = css`
    opacity : 0.01`

    return (<div>
        <Typo name="title" center>Publiez une photo</Typo>
        <Button onClick={() => openPicker("environment")} label="Photo" />
        <Button onClick={() => openPicker("user")} label="Selfie" />
        <Button onClick={() => openPicker(undefined)} label="Galerie" />
        <br /><br />
        <Typo center>{message}</Typo>
        {inputProps ? <input {...inputProps} ref={ref} css={inputCss} /> : null}
    </div>)

}



type ButtonProps = {
    label: string
    submit?: boolean
    onClick?: () => any
}
function Button(props: ButtonProps) {

    const buttonCss = css`
    ${Typos.button} ;
    display : block ;
    margin : auto ;
    margin-top : 20px ;
    background-color : rgba(255,255,255,1) ;
    border : 1px solid white ;
    border-radius : 4px ;
    padding : 10px ;
    text-align : center ;
    color : #F73A3D ;
    &:focus{
        outline : 3px solid rgba(255,255,255,0.3) ;
    }`

    return <input css={buttonCss} type={props.submit ? "submit" : "button"} onClick={props.onClick} value={props.label} />

}



type UploadFileOptions = {
    method: string;
    path: string;
    file: File;
    contentType: string;
    onProgress?: (p: number) => any;
};

function uploadFile(config: UploadFileOptions) {
    return new Promise<void>((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open(config.method, config.path);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    resolve();
                } else {
                    reject(xhr);
                }
            }
        };
        xhr.upload.onprogress = e => {
            if (config.onProgress) {
                console.log(e);
                config.onProgress(e.loaded / e.total);
            }
        };
        xhr.setRequestHeader('Content-Type', config.contentType);
        xhr.send(config.file);
    });
}
